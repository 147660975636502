@use '../sass/mixins';
@use '../sass/variables';

.service {
  background-image: radial-gradient(variables.$color-logo-blue, variables.$color-bg-1, variables.$color-bg-2, variables.$color-black-3);
  color: variables.$color-white-6;
  .container {
    max-width: 1200px;
    .title {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      padding: 25px 0;
    }
    .paragraph {
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      padding-bottom: 25px;
    }
    .cards-container {
      @include mixins.flex(row, center, center);
      flex-wrap: wrap;
      column-gap: 15px;
      width: 100%;
      @media screen and (max-width: variables.$mobile-l) {
        display: block;
      }
      .cards {
        //cursor: pointer;
        width: 30%;
        height: 400px;
        //perspective: 1000px;
        padding-bottom: 20px;
        @media screen and (max-width: variables.$mobile-l) {
          width: 100%;
        }
        //&:hover {
        //  .card {
        //    transform: rotateY(180deg);
        //  }
        //}
        .card {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          transition: transform 1s;
          //transform-style: preserve-3d;
        }
        .card-front, .card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          border-radius: 20px;
          background-image: url('../images/Background1.jpg');
          @include mixins.flex(column, center, center);
        }
        .card-front {
          color: variables.$color-white-6;
          background-color: #333;
          i {
            svg {
              width: 40px;
              height: 40px;
            }
          }
          h3 {
            font-size: 25px;
            font-weight: 600;
            padding: 20px 0 0;
            text-align: center;
          }
          hr {
            width: 50px;
            border-color: variables.$color-logo-blue;
            margin: 20px 0 5px;
          }
          p {
            font-size: 15px;
            font-weight: 300;
            padding: 20px 20px 30px;
          }
        }
        .card-back {
          color: #fff;
          background-color: #517fa4;
          transform: rotateY(180deg);
          img {
            padding-bottom: 50px;
          }
          button {
            cursor: pointer;
            border: none;
            border-radius: 25px;
            padding: 15px;
            text-transform: uppercase;
            color: variables.$color-white-6;
            background-image: linear-gradient(to left, variables.$color-dark-blue, variables.$color-bg-1, variables.$color-logo-blue);
            background-size: 200%;
            transition: background-position 0.5s ease;
            &:hover {
              background-position: right;
              transition: background-position 0.5s ease;
            }
          }
        }
      }
    } 
  }
}