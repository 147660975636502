@import url('https://fonts.googleapis.com/css2?family=Uchen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

// Color Variables

$color-black: #333;
$color-darker-black: #222;
$color-white: #ccc;
$color-full-white: #fff;
$color-logo-blue: #2596be;
$color-dark-blue: #00008b;
$color-red: #ff0000;
$color-bg-1: #192a54;
$color-bg-2: #1a2855;
$color-transparent: transparent;

// Font Variables

$font-family-1: 'Raleway';
$font-family-2: 'Roboto';

//Screen Variables

$monitor: 1920px;
$laptop-l: 1440px;
$laptop: 1024px;
$tablet: 768px;
$mobile-l: 425px;
$mobile-s: 320px;

// min-width (inf -> lowest boundary)
// max-width (-inf -> highest boundary)

//Color Black Variants

$color-black-1: #111;
$color-black-2: #222;
$color-black-3: #333;
$color-black-4: #444;
$color-black-5: #555;
$color-black-6: #666;

//Color White Variants

$color-white-1: #aaa;
$color-white-2: #bbb;
$color-white-3: #ccc;
$color-white-4: #ddd;
$color-white-5: #eee;
$color-white-6: #fff;