@use '../sass/mixins';
@use '../sass/variables';

.intro {
    background: linear-gradient(60deg, variables.$color-black-6 0%, variables.$color-white-6 50%, variables.$color-black-6 100%);
    .container {
        height: 50vh;
        max-width: 1200px !important;
        @include mixins.flex(row, center, center);
        column-gap: 20px;
        @media screen and (max-width: variables.$tablet) {
            height: 40vh;
        }
        @media screen and (max-width: variables.$mobile-l) {
            display: block;
            height: 100vh;
        }
    }
    .info {
        width: 50%;
        @media screen and (max-width: variables.$mobile-l) {
            width: 100%;
        }
        h1 {
            font-family: variables.$font-family-1;
            font-size: 60px;
            font-weight: 600;
            margin-bottom: 30px;
            @media screen and (max-width: variables.$tablet) {
                font-size: 40px;
            }
            @media screen and (max-width: variables.$mobile-l) {
                text-align: center;
                padding: 20px 0 10px;
            }
        }
        p {
            font-size: 20px;
            font-weight: 300;
            text-align: start;
            @media screen and (max-width: variables.$tablet) {
                font-size: 16px;
            }
            @media screen and (max-width: variables.$mobile-l) {
                padding-bottom: 30px;
            }
        }
    }
    .image {
        width: 50%;
        @media screen and (max-width: variables.$mobile-l) {
            width: 100%;
        }
        img {
            box-shadow: 0 0 8px 8px variables.$color-white-6;
        }
    }
}