@use '../sass/mixins';
@use '../sass/variables';

.WIP {
    text-align: center;
    background-image: radial-gradient(variables.$color-logo-blue, variables.$color-bg-1, variables.$color-bg-2, variables.$color-black-3);
    color: variables.$color-white-6;
    .container {
        padding: 60px;
        .title {
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            padding: 25px 0;
        }
        .paragraph {
            font-size: 26px;
            font-weight: 500;
            text-align: center;
            padding-bottom: 25px;
        }
        i {
            svg {
                font-size: 100px;
                animation: rotate 1s linear infinite;
                @keyframes rotate {
                    to {
                        transform: rotate(360deg);
                    }
                }
            } 
        }
    }
}