@use '../sass/mixins';
@use '../sass/variables';

.contact {
    position: relative;
    padding: 50px 0;
    background: linear-gradient(-60deg, variables.$color-black-6 0%, variables.$color-white-6 50%, variables.$color-black-6 100%);
    .container {
        text-align: center;
        .title {
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 20px;
            color: variables.$color-black-1;
        }
        .contact-form {
            width: 100%;
            margin-top: 30px;
            @include mixins.flex(row, center, center);
            column-gap: 20px;
            @media screen and (max-width: variables.$tablet) {
                @include mixins.flex(column, center, center);
            }
            .form-icon {
                @media screen and (max-width: variables.$tablet) {
                    z-index: 1;
                }
                i {
                    svg {
                        width: 400px;
                        height: 400px;
                        overflow-x: hidden;
                        transform: rotate(8deg);
                        @media screen and (max-width: variables.$tablet) {
                            position: absolute;
                            left: 100px;
                            top: 100px;
                            width: 600px;
                            height: 600px;
                            opacity: 0.5;
                        }
                        @media screen and (max-width: variables.$mobile-l) {
                            left: 25px;
                            top: 200px;
                            width: 375px;
                            height: 375px;
                        }
                        @media screen and (max-width: variables.$mobile-s) {
                            top: 320px;
                            width: 275px;
                            height: 275px;
                        }
                        path {
                            stroke: variables.$color-logo-blue;
                        }
                    } 
                }
            }
            .form-container {
                width: 50%;
                padding: 40px;
                z-index: 2;
                @media screen and (max-width: variables.$tablet) {
                    width: 65%;
                }
                @media screen and (max-width: variables.$mobile-l) {
                    width: 100%;
                }
                h1 {
                    font-size: 30px;
                    font-weight: 600;
                    padding-bottom: 10px;
                    color: variables.$color-black-1;
                }
                p {
                    font-size: 16px;
                    font-weight: 400;
                    padding-bottom: 10px;
                    color: variables.$color-black-1;
                }
                .input-box {
                    position: relative;
                    width: 100%;
                    padding: 0 10px;
                    margin-top: 30px;
                    border-radius: 20px;
                    border-bottom: 2px solid variables.$color-black-1;
                    box-shadow: 0 2px 4px 2px variables.$color-black-6;
                    background-color: variables.$color-white-6;
                    &:last-child {
                        padding: 0;
                        background-image: linear-gradient(to left, variables.$color-dark-blue, variables.$color-bg-1, variables.$color-logo-blue);
                    }
                    input, textarea {
                        width: 100%;
                        padding: 5px 0;
                        font-size: 18px;
                        margin: 10px 0;
                        outline: none;
                        border: none;
                    }
                    input {
                        &:last-child {
                            margin: 0;
                        }
                    }
                    textarea {
                        resize: none;
                    }
                    input:focus ~ label, input:valid ~ label,
                    textarea:focus ~ label, textarea:valid ~ label {
                        font-size: 12px;
                        font-weight: 500;
                        transform: translateY(-30px);
                        color: variables.$color-black-1;
                    }
                    label {
                        position: absolute;
                        left: 10px;
                        top: 4px;
                        font-size: 18px;
                        margin: 10px 0;
                        pointer-events: none;
                        transition: 0.5s;
                        color: variables.$color-black-6;
                    }
                    input[type="submit"] {
                        width: 100%;
                        cursor: pointer;
                        border: none;
                        border-radius: 20px;
                        padding: 15px;
                        text-transform: uppercase;
                        color: variables.$color-white-6;
                        background-image: linear-gradient(to left, variables.$color-dark-blue, variables.$color-bg-1, variables.$color-logo-blue);
                        background-size: 200%;
                        transition: background-position 0.5s ease;
                        &:hover {
                          background-position: right;
                          transition: background-position 0.5s ease;
                        }
                    }
                }
            }
        }
    }
}