@use '../sass/mixins';
@use '../sass/variables';

.banner {
    background: url('../images/Background1.jpg');
    .container {
        .title {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            color: variables.$color-white-6;
            padding: 40px 0;
        }
        .paragraph {
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            color: variables.$color-white-6;
            padding-bottom: 40px;
        }
    }
}