@use './sass/mixins';
@use './sass/variables';

*, ::after, ::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: variables.$font-family-2;
  padding-top: 80px;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
p {
  text-align: center;
}
path {
  stroke: variables.$color-white-6;
}
img {
  max-width: 100%;
  max-height: 100%;
}
iframe {
  border: none;
}
.container {
  padding: 0 15px;
  @media screen and (min-width: variables.$laptop) {
    max-width: variables.$laptop;
    margin: 0 auto;
  }
}