@use '../sass/variables';
@use '../sass/mixins';

footer {
    background-color: variables.$color-black-3;
    color: variables.$color-white-3;
    padding: 20px 0;
    .footer-top {
        @include mixins.flex(column, flex-start, flex-start);
        row-gap: 20px;
        border-bottom: 1px solid variables.$color-white-3;
        padding-bottom: 30px;
        @media screen and (min-width: variables.$laptop) {
            flex-direction: row;
            text-align: center;
            row-gap: 0;
            column-gap: 15px;
        }
        .footer-top-item {
            width: 100%;
            @media screen and (min-width: variables.$laptop)  {
                width: calc(33.33% - 10px);
            }
            @media screen and (max-width: variables.$tablet) {
                p {
                    text-align: start;
                }
            }
            h2 {
                color: variables.$color-white-6;
                margin-top: 20px;
            }
            &:first-child {
                h2 {
                    @include mixins.flex(column, flex-start, flex-start);
                    row-gap: 10px;
                    margin: 20px 0;
                    @media screen and (min-width: variables.$laptop) {
                        align-items: center;
                    }
                }
                .social {
                    @include mixins.flex(row, flex-start, center);
                    column-gap: 10px;
                    @media screen and (min-width: variables.$laptop) {
                        justify-content: center;
                    }
                    a {
                        font-size: 25px;
                        color: variables.$color-white-3;
                        padding: 15px 0;
                        transition: color 0.3s;
                        &:hover {
                            color: variables.$color-white-6;
                        }
                    }
                }
            }
            ul {
                @include mixins.flex(column, flex-start, flex-start);
                row-gap: 15px;
                margin-top: 25px;
                @media screen and (min-width: variables.$laptop) {
                    align-items: center;
                }
                li {
                    @include mixins.flex(row, center, center);
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }
                }
                a {
                    color: variables.$color-white-3;
                    transition: color .3s;
                    &:hover {
                        color: variables.$color-white-6;
                    }
                }
            }
        }
    }
    .footer-bottom {
        @include mixins.flex(row, center, center);
        padding: 20px 0;
        p {
            margin: 0;
        }
    }
}