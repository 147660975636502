@use '../sass/mixins';
@use '../sass/variables';

.menu {
    position: fixed;
    top: 80px;
    right: -100%;
    width: calc(50%);
    height: 100%;
    background-color: variables.$color-darker-black;
    transition: right 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    padding: 20px 0;
    font-size: 20px;
    &.active {
        right: 0;
    }
    @media screen and (min-width: variables.$laptop) {
        position: static;
        @include mixins.flex(row, flex-end, center);
        background-color: variables.$color-transparent;
        column-gap: 30px;
        &.active {
            position: static;
            @include mixins.flex(row, flex-end, center);
            background-color: variables.$color-transparent;
            column-gap: 30px;
        }
    }
    @media screen and (max-width: variables.$mobile-l) {
        width: 100%;
    }
    li {
        position: relative;
        line-height: 2;
        width: 100%;
        text-align: start;
        @media screen and (min-width: variables.$laptop) {
            width: auto;
            text-align: left;
        }
        @media screen and (max-width: calc(variables.$laptop - 1px)) {
            padding-left: 25px;
        }
        @media screen and (max-width: variables.$mobile-l) {
            text-align: center;
            padding-left: 0;
        }
        a {
            color: variables.$color-white;
            transition: color 0.2s ease-in-out;
            &:hover {
                color: variables.$color-full-white;
                transition: color 0.2s ease-in-out;
            }
            &.active {
                color: variables.$color-full-white;
            }
        }
    }
}