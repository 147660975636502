@use '../sass/mixins';
@use '../sass/variables';

.carousel {
  @include mixins.flex(column, center, center);
  .slideshow {
    position: relative;
    width: 100%;
    .main-img {
      display: block;
      transition: all 500ms;
    }
    .slide {
      display: none;
    }
    .actions {
      position: absolute;
      padding: 0 50px;
      top: 42%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: variables.$laptop) {
        padding: 0 30px;
      }
      @media screen and (max-width: variables.$mobile-s) {
        padding: 0 25px;
      }
      button {
        @include mixins.flex('', center, center);
        background: variables.$color-white-4;
        color: variables.$color-black-1;
        border: 1px solid variables.$color-black-1;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        @media screen and (max-width: variables.$laptop) {
          width: 45px;
          height: 45px;
        }
        @media screen and (max-width: variables.$laptop) {
          width: 35px;
          height: 35px;
        }
        @media screen and (max-width: variables.$mobile-s) {
          width: 30px;
          height: 30px;
        }
        svg {
          position: absolute;
          width: 25px;
          height: 25px;
          @media screen and (max-width: variables.$laptop) {
            width: 20px;
            height: 20px;
          }
          @media screen and (max-width: variables.$mobile-s) {
            width: 15px;
            height: 15px;
          }
        }
        &:hover {
          background: variables.$color-black-4;
          color: variables.$color-white-6;
          transition: all 0.2s ease-in-out;
        }
      }
    }
    .dots {
      display: flex;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      .dot {
        width: 20px;
        height: 20px;
        border: 2px solid variables.$color-white-6;
        border-radius: 50%;
        margin: 0 5px;
        background-color: variables.$color-white-6;
        transition: background-color 0.3s ease-in-out;
        &.active {
          background-color: variables.$color-black-1;
          transition: background-color 0.3s ease-in-out;
        }
      }
    }
  }
}